<template>
  <div>
    <v-card-text class="py-8">
      <v-row>
        <v-col cols="12" sm="6" class="py-0">
          <v-autocomplete
            dense
            v-model="responsableOKR"
            :search-input.sync="search"
            :items="itemsParticipantes"
            item-text="name"
            item-value="code"
            no-data-text="No se encontraron datos"
            label="Responsable OKR"
            placeholder="Responsable OKR"
            prepend-icon="mdi-database-search"
            return-object
          >
            <template v-slot:selection="data">
              <v-chip small> {{ data.item.name }} </v-chip>
            </template>
            <template v-slot:item="data">
              <template>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.name"></v-list-item-title>
                  <v-list-item-subtitle>
                    {{ `${data.item.dni} - ${data.item.idsubarea != '' ? data.item.idsubarea : 'OTROS'}` }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="3" class="py-0">
          <v-text-field dense v-model="semanaFecha" readonly> </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="6" class="py-0">
          <v-col class="py-0">
            <h3 class="my-2">PROGRESO - PROGRESS</h3>
          </v-col>
          <v-col cols="12" sm="12" class="py-0">
            <v-data-table
              dense
              :headers="cabecerasLogro"
              :items="logrosSemana"
              :items-per-page="-1"
              class="border"
              hide-default-footer
            >
              <template v-slot:top>
                <div class="primary w-100 d-flex justify-space-between px-2 white--text body-1" style="height: 40px;">
                  <div class="py-1 pt-2">
                    ¿Qué&nbsp;<u>l</u>ograste esta semana? - What did you achieve this week
                  </div>
                  <div class="d-flex align-center">
                    <v-divider class="mx-1" inset vertical style="background:#fff"></v-divider>
                    <v-btn
                      color="white"
                      fab
                      icon
                      small
                      @click="addRowsLogro"
                      v-shortkey="['ctrl', 'l']"
                      @shortkey="addRowsLogro"
                    >
                      <v-icon dark>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                </div>
              </template>
              <template v-slot:[`item.realizado`]="{ item }">
                <v-chip small :color="item.realizado.color" dark @click="openDialogFile(item)">{{
                  item.realizado.text
                }}</v-chip>
              </template>
              <template v-slot:[`item.accion`]="{ item }">
                <span v-if="item.estadoDelete"> {{ item.accion }} </span>
                <v-edit-dialog :return-value.sync="item.accion" v-if="!item.estadoDelete">
                  {{ item.accion }}
                  <template v-slot:input>
                    <v-text-field v-model="item.accion" label="Acción" single-line></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:[`item.entregable`]="{ item }">
                <v-chip
                  v-if="!item.estadoEntregable"
                  color="primary"
                  dark
                  close
                  @click:close="
                    item.estadoEntregable = true;
                    item.realizado = { value: 'NO', color: 'red', text: 'NO REALIZADO' };
                    item.nombreEntregable = null;
                  "
                >
                  <span> {{ item.nombreEntregable }} </span>
                </v-chip>
                <v-file-input
                  v-else
                  class="pt-2 my-0"
                  small
                  :rules="rules"
                  hide-details
                  v-model="item.entregable"
                  accept=".zip,.rar,.7zip,.pdf,.png,.jpg,.jpeg,.pptx,.docx,.xlsx"
                  show-size
                  label="Evidencia"
                  clearable
                  clear-icon="mdi-close"
                  @change="changeInputFileTable($event, item)"
                ></v-file-input>
              </template>
              <template v-slot:[`item.acciones`]="{ item }">
                <v-icon v-if="!item.estadoDelete" small @click="deleteItemLogro(item)">mdi-delete</v-icon>
              </template>
              <template v-slot:no-data>
                <span>No se encontró información</span>
              </template>
            </v-data-table>
          </v-col>
        </v-col>
        <v-col cols="12" sm="12" md="6" class="py-0">
          <v-col class="py-0">
            <h3 class="my-2">PLANES - PLANS</h3>
          </v-col>
          <v-col cols="12" sm="12" class="py-0">
            <div>
              <v-data-table
                dense
                :headers="cabecerasPlan"
                :items="planes"
                :items-per-page="-1"
                class="border"
                hide-default-footer
              >
                <template v-slot:top>
                  <div class="primary w-100 d-flex justify-space-between px-2 white--text body-1" style="height: 52px;">
                    <div class="py-1">
                      ¿Qué&nbsp;<u>p</u>laneas hacer la próxima semana? - What do you plan to do next week
                    </div>
                    <div class="d-flex align-center">
                      <v-divider class="mx-1" inset vertical style="background:#fff"></v-divider>
                      <v-btn
                        color="white"
                        fab
                        icon
                        small
                        @click="addRowsPlan"
                        v-shortkey="['ctrl', 'p']"
                        @shortkey="addRowsPlan"
                      >
                        <v-icon dark>mdi-plus</v-icon>
                      </v-btn>
                    </div>
                  </div>
                  <!--<v-toolbar flat color="primary">
                    <v-toolbar-title class="white--text body-1"
                      >¿Qué <u>p</u>laneas hacer la próxima semana? - What do you plan to do next week</v-toolbar-title
                    >
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      fab
                      dark
                      small
                      class="mb-2"
                      @click="addRowsPlan"
                      v-shortkey="['ctrl', 'p']"
                      @shortkey="addRowsPlan"
                    >
                      <v-icon dark>mdi-plus</v-icon>
                    </v-btn>
                  </v-toolbar>-->
                </template>
                <template v-slot:[`item.accion`]="{ item }">
                  <v-edit-dialog :return-value.sync="item.accion">
                    {{ item.accion }}
                    <template v-slot:input>
                      <v-text-field v-model="item.accion" label="Acción" single-line></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.acciones`]="{ item }">
                  <v-icon small @click="deleteItemPlan(item.id)">mdi-delete</v-icon>
                </template>
                <template v-slot:no-data>
                  <span>No se encontró información</span>
                </template>
              </v-data-table>
            </div>
          </v-col>
        </v-col>
        <v-col cols="12" sm="12" md="12" class="py-0 mt-4">
          <v-col class="py-0">
            <h3 class="my-2">PROBLEMAS - PROBLEMS</h3>
          </v-col>
          <v-col cols="12" sm="12" class="py-0">
            <v-data-table
              dense
              :headers="cabecerasProblemas"
              :items="problemas"
              :items-per-page="-1"
              class="border"
              hide-default-footer
            >
              <template v-slot:top>
                <div class="primary w-100 d-flex justify-space-between px-2 white--text body-1" style="height: 40px;">
                  <div class="py-1 pt-2">
                    ¿Qué&nbsp;p<u>r</u>oblemas estás enfrentando? ¿Te gustaría ayuda? - What problems are you facing you
                    would like help with
                  </div>
                  <div class="d-flex align-center">
                    <v-divider class="mx-1" inset vertical style="background:#fff"></v-divider>
                    <v-btn
                      color="white"
                      fab
                      icon
                      small
                      @click="addRowsProblemas"
                      v-shortkey="['ctrl', 'r']"
                      @shortkey="addRowsProblemas"
                    >
                      <v-icon dark>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                </div>
              </template>
              <template v-slot:[`item.accion`]="{ item }">
                <v-edit-dialog :return-value.sync="item.accion">
                  {{ item.accion }}
                  <template v-slot:input>
                    <v-text-field v-model="item.accion" label="Acción" single-line></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:[`item.acciones`]="{ item }">
                <v-icon small @click="deleteItemProblema(item.id)">mdi-delete</v-icon>
              </template>
              <template v-slot:no-data>
                <span>No se encontró información</span>
              </template>
            </v-data-table>
          </v-col>
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title class="py-3">
          Editar Estado
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-8">
          <v-row>
            <v-col cols="12" sm="12" class="py-0">
              <v-select
                dense
                v-model="selectedStatus"
                :items="itemsStatus"
                label="Estado"
                item-text="text"
                item-value="value"
                required
              >
                <template v-slot:selection="data">
                  <v-chip :color="data.item.color" dark> {{ data.item.text }}</v-chip>
                </template>
              </v-select>
            </v-col>
            <v-col v-if="statusComplete" cols="12" sm="12" class="py-0">
              <v-file-input
                dense
                :rules="rules"
                v-model="evidencia"
                accept=".zip,.rar,.7zip,.pdf,.png,.jpg,.jpeg,.pptx,.docx,.xlsx"
                show-size
                label="Evidencia"
                clearable
                clear-icon="mdi-close"
                @change="changeInputFile($event)"
              ></v-file-input>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="closeDialogUploadFile">Cerrar</v-btn>
          <v-btn color="primary" text @click="confirmDialogUploadFile">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn v-auth-acl="'gh-okr-cre_okr-insert'" color="info" submit @click="guardar()">{{
        okrActual ? 'Actualizar' : 'Guardar'
      }}</v-btn>
    </v-card-actions>
    <div v-if="showLoading" class="progress-background">
      <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
    </div>
  </div>
</template>

<script>
import OKRService from '../services/OKRService';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import Swal from 'sweetalert2';
import { decryptAES, decryptData } from '@/components4x/utils/utils4x';
export default {
  name: 'MetodologiaOKRFormulario',
  components: {},
  data: () => ({
    showLoading: false,
    okrService: null,
    responsableOKR: '',
    search: '',
    itemsStatus: [
      { value: 'RE', color: 'green', text: 'REALIZADO' },
      { value: 'NO', color: 'red', text: 'NO REALIZADO' }
    ],
    selectedStatus: 'RE',
    cabecerasLogro: [
      { text: 'N°', align: 'start', value: 'index', width: '70' },
      { text: 'Acción', value: 'accion', width: '200' },
      { text: 'Estatus', align: 'start', value: 'realizado', width: '150' },
      { text: 'Entregable', align: 'start', value: 'entregable', width: '340' },
      { text: 'Acciones', value: 'acciones', width: '150' }
    ],
    cabecerasPlan: [
      { text: 'N°', align: 'start', value: 'index', width: '70' },
      { text: 'Acción', value: 'accion', width: '200' },
      { text: 'Acciones', value: 'acciones', width: '150' }
    ],
    errorFile: false,
    evidencia: null,
    nombreEvidencia: '',
    idEliminados: [],
    rules: [
      (value) => !value || value.size < 25000000 || 'El archivo debe de tener menos de 25 MB!',
      (value) => {
        if (value === null || value === undefined) return true;
        const res = value.name.slice(value.name.lastIndexOf('.') + 1, value.name.length).toLowerCase();
        if (
          res != 'exe' &&
          res != 'sql' &&
          res != 'jar' &&
          res != 'air' &&
          res != 'src' &&
          res != 'vb' &&
          res != 'bat' &&
          res != 'dll' &&
          res != 'com' &&
          res != 'bin' &&
          res != 'apk' &&
          res != 'app' &&
          res != 'cgi' &&
          res != 'wsf' &&
          res != 'gadget' &&
          res != 'log' &&
          res != 'css' &&
          res != 'sqlite' &&
          res != 'psd' &&
          res != 'ai' &&
          res != 'bak' &&
          res != 'js' &&
          res != 'cmd'
        )
          return true;
        return 'No se permite ese tipo de archivos.';
      }
    ],
    cabecerasProblemas: [
      { text: 'N°', align: 'start', value: 'index', width: '100' },
      { text: 'Acción', value: 'accion', width: '300' },
      { text: 'Acciones', value: 'acciones', width: '50' }
    ],
    logrosSemana: [],
    planes: [],
    itemsParticipantes: [],
    problemas: [],
    semanaAux: '',
    semanaFecha: '',
    statusComplete: false,
    itemFile: '',
    dialog: false,
    okrActual: false,
    idOKR: ''
  }),
  computed: {},
  watch: {
    selectedStatus(val) {
      this.statusComplete = val === 'RE';
      this.evidencia = null;
    },
    async responsableOKR(val) {
      if (val != null) {
        await this.cargarOKR(val.code);
        if (!this.okrActual) await this.cargarSemanaBefore(val.code);
      }
    }
  },
  methods: {
    async alertDialog(type, title) {
      return await Swal.fire({
        icon: type,
        title: title,
        confirmButtonText: `OK`
      });
    },
    async changeInputFileTable($event, item) {
      const file = $event === null || $event === undefined ? null : $event;
      let estadoAux = true;
      if (file === null) {
        this.logrosSemana.forEach((el) => {
          if (el.id === item.id) {
            el.entregable = null;
            el.realizado = { value: 'NO', color: 'red', text: 'NO REALIZADO' };
          }
        });
        return;
      }

      if (file.size > 25000000) {
        estadoAux = false;
        await this.alertDialog('warning', 'Debe de subir su evidencia para cambiar su estado a "REALIZADO"');
        this.logrosSemana.forEach((el) => {
          if (el.id === item.id) {
            el.entregable = null;
            el.realizado = { value: 'NO', color: 'red', text: 'NO REALIZADO' };
          }
        });
        return;
      }
      const res = file.name.slice(file.name.lastIndexOf('.') + 1, file.name.length).toLowerCase();
      if (
        res == 'exe' ||
        res == 'sql' ||
        res == 'jar' ||
        res == 'air' ||
        res == 'src' ||
        res == 'vb' ||
        res == 'bat' ||
        res == 'dll' ||
        res == 'com' ||
        res == 'bin' ||
        res == 'apk' ||
        res == 'app' ||
        res == 'cgi' ||
        res == 'wsf' ||
        res == 'gadget' ||
        res == 'log' ||
        res == 'css' ||
        res == 'sqlite' ||
        res == 'psd' ||
        res == 'ai' ||
        res == 'bak' ||
        res == 'js' ||
        res == 'cmd'
      ) {
        estadoAux = false;
        await this.alertDialog('warning', 'Tamaño o tipo de archivo no permitido');
        this.logrosSemana.forEach((el) => {
          if (el.id === item.id) {
            el.entregable = null;
            el.realizado = { value: 'NO', color: 'red', text: 'NO REALIZADO' };
          }
        });
        return;
      }

      if (estadoAux) {
        this.logrosSemana.forEach((el) => {
          if (el.id === item.id) {
            el.realizado = { value: 'RE', color: 'green', text: 'REALIZADO' };
          }
        });
      }
    },
    changeInputFile($event) {
      const file = $event === null || $event === undefined ? null : $event;

      if (file === null) {
        return;
      }

      this.errorFile = false;

      if (file.size > 25000000) {
        this.errorFile = true;
      }

      const res = file.name.slice(file.name.lastIndexOf('.') + 1, file.name.length).toLowerCase();
      if (
        res == 'exe' ||
        res == 'sql' ||
        res == 'jar' ||
        res == 'air' ||
        res == 'src' ||
        res == 'vb' ||
        res == 'bat' ||
        res == 'dll' ||
        res == 'com' ||
        res == 'bin' ||
        res == 'apk' ||
        res == 'app' ||
        res == 'cgi' ||
        res == 'wsf' ||
        res == 'gadget' ||
        res == 'log' ||
        res == 'css' ||
        res == 'sqlite' ||
        res == 'psd' ||
        res == 'ai' ||
        res == 'bak' ||
        res == 'js' ||
        res == 'cmd'
      ) {
        this.errorFile = true;
      }
    },
    openDialogFile(item) {
      this.evidencia = item.entregable;
      this.selectedStatus = item.realizado.value;
      this.itemFile = item.id;
      this.dialog = true;
    },
    async confirmDialogUploadFile() {
      const file = this.evidencia === null || this.evidencia === undefined ? null : this.evidencia;

      if (this.selectedStatus === 'RE') {
        if (file == null) {
          await this.alertDialog('warning', 'Debe de subir su evidencia para cambiar su estado a "REALIZADO"');
          return;
        }
        if (this.errorFile) {
          await this.alertDialog('warning', 'Tamaño o tipo de archivo no permitido');
          return;
        }
        this.logrosSemana.forEach((el) => {
          if (el.id === this.itemFile) {
            el.entregable = file;
            el.realizado =
              this.selectedStatus === 'RE'
                ? { value: 'RE', color: 'green', text: 'REALIZADO' }
                : { value: 'NO', color: 'red', text: 'NO REALIZADO' };
          }
        });
        await this.closeDialogUploadFile();
      }
    },
    closeDialogUploadFile() {
      this.itemFile = '';
      this.selectedStatus = 'NO';
      this.evidencia = null;
      this.dialog = false;
    },
    async obtenerSemana() {
      this.showLoading = true;
      const res = await this.okrService.get().execResource('fecha', {
        fecha: moment().format('YYYY-MM-DD')
      });
      this.semanaAux = res;
      this.semanaFecha = 'SEMANA ' + res;
      this.showLoading = false;
    },
    async cargarResponsables() {
      this.showLoading = true;
      const responsable = localStorage.getItem('lrd5qwlr674');
      const decryptId = decryptData(responsable);
      const res = await this.okrService.get().execResource('responsables', {
        idempresa: decryptAES(localStorage.getItem('emp'))
      });
      if (res.length > 0) {
        res.forEach((el) => {
          if (el.dni != '')
            if (el.idsubarea != '')
              this.itemsParticipantes.push({
                id: uuidv4(),
                code: el.idresponsable,
                dni: el.idtrabajador,
                idsubarea: el.idsubarea,
                name: el.nombresall,
                email: el.email,
                leader: false,
                new: false,
                object: {}
              });
        });
        this.itemsParticipantes.forEach((el) => {
          if (el.code == decryptId) {
            this.responsableOKR = el;
          }
        });
      } else {
        this.itemsParticipantes = [];
      }

      this.showLoading = false;
    },
    async cargarSemanaBefore(responsable) {
      this.showLoading = true;
      this.logrosSemana = [];
      const res = await this.okrService.get().execResource('semanal', {
        idempresa: decryptAES(localStorage.getItem('emp')),
        semana: this.semanaAux,
        idresponsable: responsable
      });
      if (res.data.length > 0) {
        res.data.forEach((element, index) => {
          this.logrosSemana.push({
            id: uuidv4(),
            idtarea: '',
            okrbefore: element.idokr,
            itembefore: element.item,
            accion: element.descripcion,
            index: (index + 1).toString().padStart(4, '0'),
            entregable: null,
            nombreEntregable: null,
            realizado: { value: 'NO', color: 'red', text: 'NO REALIZADO' },
            estadoEntregable: true,
            estadoDelete: true
          });
        });
      } else {
        this.logrosSemana = [];
      }
      this.showLoading = false;
    },
    async cargarOKR(responsable) {
      this.showLoading = true;
      this.logrosSemana = [];
      this.planes = [];
      this.problemas = [];
      this.idOKR = '';
      this.okrActual = false;
      const res = await this.okrService.get().execResource('cargarOKR', {
        idempresa: decryptAES(localStorage.getItem('emp')),
        semana: this.semanaAux,
        idusuario: responsable,
        idresponsable: responsable,
        anio: moment().format('YYYY')
      });
      this.okrActual = res.status;
      if (res.data.length > 0) {
        this.idOKR = res.data[0].idokr;
        if (res.data[0].tareas.length > 0) {
          let indexP = 0;
          res.data[0].tareas.forEach((element) => {
            if (element.tipo != 'PL') {
              this.logrosSemana.push({
                id: uuidv4(),
                idtarea: element.item,
                okrbefore: '',
                itembefore: '',
                itemreferencia: element.itemreferencia,
                accion: element.descripcion,
                index: element.correlativo,
                entregable: null,
                nombreEntregable: element.nombrefichero,
                estadoEntregable: element.nombrefichero != null ? false : true,
                realizado:
                  element.tipo == 'SI'
                    ? { value: 'RE', color: 'green', text: 'REALIZADO' }
                    : { value: 'NO', color: 'red', text: 'NO REALIZADO' },
                estadoDelete: element.istarea ? true : element.referenciaitem != '' ? true : false
              });
            } else {
              this.planes.push({
                id: uuidv4(),
                accion: element.descripcion,
                index: (indexP + 1).toString().padStart(4, '0')
              });
              indexP++;
            }
          });
        }
        if (res.data[0].problemas.length > 0) {
          res.data[0].problemas.forEach((el) => {
            this.problemas.push({
              id: uuidv4(),
              accion: el.descripcion,
              index: el.item
            });
          });
        }
      } else {
        this.logrosSemana = [];
        this.planes = [];
        this.problemas = [];
        this.idOKR = '';
        this.okrActual = false;
      }
      this.showLoading = false;
    },
    addRowsLogro() {
      const index = this.logrosSemana.length <= 0 ? 1 : this.logrosSemana.length + 1;
      this.logrosSemana.push({
        id: uuidv4(),
        idtarea: '',
        index: index.toString().padStart(4, '0'),
        accion: '',
        realizado: { value: 'NO', color: 'red', text: 'NO REALIZADO' },
        entregable: null,
        okrbefore: '',
        itembefore: '',
        nombreEntregable: null,
        estadoEntregable: true,
        estadoDelete: false
      });
    },
    addRowsPlan() {
      const index = this.planes.length <= 0 ? 1 : this.planes.length + 1;
      this.planes.push({ id: uuidv4(), index: index.toString().padStart(4, '0'), accion: '' });
    },
    addRowsProblemas() {
      const index = this.problemas.length <= 0 ? 1 : this.problemas.length + 1;
      this.problemas.push({ id: uuidv4(), index: index.toString().padStart(4, '0'), accion: '' });
    },
    cancelar() {},
    async guardar() {
      if (this.responsableOKR.length <= 0) {
        await this.alertDialog('warning', 'Ingrese un Responsable');
        return;
      }

      if (this.logrosSemana.length <= 0) {
        await this.alertDialog('warning', 'Los progresos están vacíos');
        return;
      }

      if (this.planes.length <= 0) {
        await this.alertDialog('warning', 'Los planes están vacíos');
        return;
      }
      this.showLoading = true;
      const problemasAux = [];
      const tareas = [];
      const planesAux = [];
      this.problemas.forEach((el) => {
        if (el.accion !== '') problemasAux.push({ item: el.index, descripcion: el.accion });
      });
      this.logrosSemana.forEach((el) => {
        tareas.push({
          idtarea: el.idtarea,
          idokr: el.okrbefore,
          itembefore: el.itembefore,
          item: el.index,
          semana: this.semanaAux,
          descripcion: el.accion,
          entregable: el.nombreEntregable != null ? el.nombreEntregable : el.entregable,
          estadoFile: el.nombreEntregable != null ? true : false,
          tipo: el.realizado.value == 'RE' ? 'SI' : 'NO'
        });
      });
      this.planes.forEach((el) => {
        if (el.accion !== '')
          planesAux.push({ correlativo: el.index, semana: this.semanaAux + 1, descripcion: el.accion });
      });

      const formData = new FormData();
      const emp = decryptAES(localStorage.getItem('emp'));
      formData.append('idempresa', emp);
      formData.append('idokr', this.idOKR);
      formData.append('idresponsable', this.responsableOKR.code);
      formData.append('semana', this.semanaAux);
      formData.append('bandera', this.okrActual ? 1 : 0);
      formData.append('problemas', JSON.stringify(problemasAux));
      formData.append('planes', JSON.stringify(planesAux));

      tareas.forEach((el, index) => {
        formData.append(`tareas[${index}][id]`, el.idtarea);
        formData.append(`tareas[${index}][correlativo]`, el.item);
        formData.append(`tareas[${index}][entregable]`, el.entregable);
        formData.append(`tareas[${index}][idokr]`, el.idokr);
        formData.append(`tareas[${index}][itembefore]`, el.itembefore);
        formData.append(`tareas[${index}][semana]`, el.semana);
        formData.append(`tareas[${index}][descripcion]`, el.descripcion);
        formData.append(`tareas[${index}][tipo]`, el.tipo);
        formData.append(`tareas[${index}][estado]`, el.estadoFile);
      });
      if (this.idEliminados.length > 0) {
        await this.idEliminados.forEach((el) => {
          this.okrService.post().execResource('eliminarFile', {
            idokr: el.idokr,
            iditem: el.idtarea,
            nombrefichero: el.nombreEntregable
          });
        });
      }
      const res = await this.okrService.post().execResource('okr', formData);
      this.showLoading = false;
      if (res.status) {
        await this.alertDialog('success', res.data);
        await this.$router.push({ path: '/okr/matriz-seguimiento' });
      } else {
        await this.alertDialog('error', res.data);
      }
    },
    async deleteItemLogro(item) {
      if (item.idtarea != '') {
        this.idEliminados.push({
          idtarea: item.idtarea,
          idokr: this.idOKR,
          nombreEntregable: item.nombreEntregable
        });
      }
      const i = this.logrosSemana.findIndex((el) => el.id === item.id);
      this.logrosSemana.splice(i, 1);
      this.logrosSemana.map((el, index) => (el.index = (index + 1).toString().padStart(4, '0')));
    },
    deleteItemPlan(id) {
      const i = this.planes.findIndex((el) => el.id === id);
      this.planes.splice(i, 1);
      this.planes.map((el, index) => (el.index = (index + 1).toString().padStart(4, '0')));
    },
    deleteItemProblema(id) {
      const i = this.problemas.findIndex((el) => el.id === id);
      this.problemas.splice(i, 1);
      this.problemas.map((el, index) => (el.index = (index + 1).toString().padStart(4, '0')));
    },
    close() {
      console.log('Dialog closed');
    }
  },
  async mounted() {},
  async created() {
    this.okrService = this.$httpService(new OKRService(), this);
    await this.obtenerSemana();
    await this.cargarResponsables();
  }
};
</script>
