class OKRService {
  constructor() {}
  resources = () => ({
    responsables: {
      uri: `/v1/responsables`,
      method: ['get']
    },
    fecha: {
      uri: `/v1/semana`,
      method: ['get']
    },
    okr: {
      uri: `/v1/okr`,
      method: ['post'],
      headers: { 'Content-Type': 'multipart/form-data' }
    },
    semanal: {
      uri: `/v1/okr/semanal`,
      method: ['get']
    },
    cargarOKR: {
      uri: `/v1/okr/indicador`,
      method: ['get']
    },
    eliminarFile: {
      uri: `/v1/okr/eliminartarea`,
      method: ['post'],
      headers: { 'Content-Type': 'application/json' }
    }
  });
}

export default OKRService;
